import React from 'react';
import BlockApps from './BlockApps';
import BlockButton from './BlockButton';
import BlockCardLinkList from './BlockCardLinkList';
import BlockCarousel from './BlockCarousel';
import BlockComparison from './BlockComparison';
import BlockContent from './BlockContent';
import BlockContentColumns from './BlockContentColumns';
import BlockEmbed from './BlockEmbed';
import BlockFourUp from './BlockFourUp';
import BlockFullPhoto from './BlockFullPhoto';
import BlockGatedContent from './BlockGatedContent';
import BlockHero from './BlockHero';
import BlockHeroAbout from './BlockHeroAbout';
import BlockHeroCareers from './BlockHeroCareers';
import BlockHeroContact from './BlockHeroContact';
import BlockHeroOverview from './BlockHeroOverview';
import BlockHeroSimple from './BlockHeroSimple';
import BlockHeroStories from './BlockHeroStories';
import BlockHomeApps from './BlockHomeApps';
import BlockHomePartners from './BlockHomePartners';
import BlockImage from './BlockImage';
import BlockItems from './BlockItems';
import BlockLargePhoto from './BlockLargePhoto';
import BlockLeadership from './BlockLeadership';
import BlockList from './BlockList';
import BlockMailchimp from './BlockMailchimp';
import BlockMatrix from './BlockMatrix';
import BlockPageNav from './BlockPageNav';
import BlockPilotContact from './BlockPilotContact';
import BlockPrivacy from './BlockPrivacy';
import BlockSection from './BlockSection';
import BlockTest from './BlockTest';
import BlockTestimonials from './BlockTestimonials';
import TimelineBlock from './BlockTimeline';

const SliceZone = ({
  sliceZone,
  page,
  global,
  title,
  data,
  lang,
  altLangs,
  slug,
  path,
  pageContext,
  id,
  prismicId,
}) => {
  const sliceComponents = {
    block_apps: BlockApps,
    block_button: BlockButton,
    block_4_up: BlockFourUp,
    block_list: BlockList,
    hero_careers: BlockHeroCareers,
    block_items: BlockItems,
    block_gated_content: BlockGatedContent,
    block_full_photo: BlockFullPhoto,
    block_partners: BlockHomePartners,
    testimonials: BlockTestimonials,
    hero_home: BlockHero,
    timeline_block: TimelineBlock,
    block_large_photo: BlockLargePhoto,
    hero_about: BlockHeroAbout,
    hero_simple: BlockHeroSimple,
    card_link_list: BlockCardLinkList,
    leadership_blocks: BlockLeadership,
    hero_contact: BlockHeroContact,
    block_pilot_contact: BlockPilotContact,
    hero_overview: BlockHeroOverview,
    block_overview_apps: BlockHomeApps,
    hero_blog: BlockHeroStories,
    privacy_content: BlockPrivacy,
    mailchimp: BlockMailchimp,
    block_section: BlockSection,
    block_test: BlockTest,
    block_content: BlockContent,
    content_carousel: BlockCarousel,
    block_page_nav: BlockPageNav,
    block_image: BlockImage,
    block_content_columns: BlockContentColumns,
    blockmatrix: BlockMatrix,
    blockcomparison: BlockComparison,
    embed: BlockEmbed,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    if (slice) {
      const SliceComponent = sliceComponents[slice.slice_type];
      if (SliceComponent) {
        return (
          <SliceComponent
            id={id}
            prismicId={prismicId}
            lang={lang}
            altLangs={altLangs}
            path={path}
            pageContext={pageContext}
            slug={slug}
            slice={slice}
            key={`slice-${index}`}
            index={index}
            page={page}
            global={global}
            title={title}
            data={data}
          />
        );
      }
    }
    return null;
  });

  return sliceZoneContent;
};

export default SliceZone;
