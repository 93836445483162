import React, { Component } from 'react';

// WARNING! This block is broken
// Somehow Prismic refuses to render this component even though it's set up the exact same way as the others.
class BlockSection extends Component {
  render() {
    const {
      slice: { primary, items },
    } = this.props;

    return (
      <section className="[ BlockSection ] container relative pt-16 md:pt-24">
        HERE!
      </section>
    );

    // return (
    //   <section className="[ BlockSection ] container relative pt-16 md:pt-24">
    //     <div className="grid grid-cols-12 gap-x-6 md:mb-10">
    //       <div
    //         className={`fade-up col-span-12 mb-10 ${
    //           primary.eyebrow != null
    //             ? "md:col-span-7"
    //             : "md:col-span-6 md:mb-0"
    //         }`}
    //       >
    //         HERE!
    //         {primary.heading_tag === "h1" ? (
    //           <h1 className={primary.eyebrow != null ? "h1" : "h0"}>
    //             {primary.heading}
    //           </h1>
    //         ) : (
    //           <h2 className={primary.eyebrow != null ? "h1" : "h0"}>
    //             {primary.heading}
    //           </h2>
    //         )}
    //       </div>

    //       {/* {primary.eyebrow != null ? (
    //         <React.Fragment>
    //           {primary.description1 && (
    //             <div className="fade-up col-span-12 md:col-start-1 md:col-end-6 lg:col-end-5 md:mb-10">
    //               <p className="opacity-50 text-lg md:text-2xl">
    //                 {primary.description1}
    //               </p>
    //             </div>
    //           )}
    //         </React.Fragment>
    //       ) : (
    //         <React.Fragment>
    //           {primary.description1 && (
    //             <div className="fade-up col-span-12 md:col-span-6 md:pl-10">
    //               <p className="text-lg md:text-2xl lg:text-3xl">
    //                 {primary.description1}
    //               </p>
    //             </div>
    //           )}
    //         </React.Fragment>
    //       )} */}
    //     </div>

    //     {/* <div className="grid grid-cols-12 gap-x-6">
    //       {primary.eyebrow != null && (
    //         <div className="col-span-12 md:col-span-2 mt-8 md:mt-0">
    //           <span className="eyebrow-name">{primary.eyebrow}</span>
    //         </div>
    //       )}
    //       <div className="html-content col-span-12 md:col-span-6 md:col-start-6">
    //         {primary.content_1.richText.length > 0 && (
    //           <PrismicRichText
    //             field={primary.content_1.richText}
    //             components={{
    //               paragraph: ({ children }) => (
    //                 <p className="text-lg md:text-xl">{children}</p>
    //               ),
    //             }}
    //           />
    //         )}
    //         {primary.content_2.richText.length > 0 && (
    //           <div className="content--story">
    //             <PrismicRichText field={primary.content_2.richText} />
    //           </div>
    //         )}
    //       </div>
    //     </div> */}
    //   </section>
    // );
  }
}

export default BlockSection;
