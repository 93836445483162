import React, { Component } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import { gsap } from 'gsap';
import logo from '../images/the-routing-company.svg';

export const Heading = ({
  headingTag = 'h1',
  looksLike,
  className = 'md:mb-10 color--primary fade-up',
  children,
}) => {
  const classStr = `${looksLike || headingTag} ${className}`;
  switch (headingTag) {
    case 'h1':
      return <h1 className={classStr}>{children}</h1>;
    case 'h2':
      return <h2 className={classStr}>{children}</h2>;
    case 'h3':
      return <h3 className={classStr}>{children}</h3>;
    case 'h4':
      return <h4 className={classStr}>{children}</h4>;
    case 'h5':
      return <h5 className={classStr}>{children}</h5>;
    case 'h6':
      return <h6 className={classStr}>{children}</h6>;
    default:
      return <h1 className={classStr}>{children}</h1>;
  }
};

class BlockHeroOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  render() {
    const { primary } = this.props.slice;
    const { global } = this.props;

    return (
      <header className="[ BlockHeroOverview ] container">
        {primary.heading && (
          <div className="grid grid-cols-12 pt-10 md:pt-16">
            <div className="col-span-12 md:col-span-10">
              <Heading
                headingTag={primary?.heading_tag ?? 'h1'}
                looksLike={primary?.heading_tag_looks_like}
              >
                {primary.heading}
              </Heading>
            </div>
            <div className="col-span-2 hidden md:block">
              <div className="flex justify-end">
                <img
                  src={logo}
                  alt="The Routing Company"
                  className="fade-up logo"
                />
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-12 mt-8 md:mt-10 md:mb-10">
          <div className="col-span-12 md:col-span-2">
            <span className="eyebrow-name fade-up">{primary.eyebrow}</span>
          </div>
        </div>
        <div className="grid grid-cols-12 mb-12">
          <div className="html-content col-span-12 md:col-span-5 mb-10 md:mb-0">
            <PrismicRichText
              field={primary.content.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="text-lg md:text-3xl fade-up">{children}</p>
                ),
              }}
            />
            {primary.show_brochure && global?.brochure_link?.url && (
              <div className="fade-up">
                <a
                  href={global.brochure_link.url}
                  target="_blank"
                  className="btn--rounded no-underline"
                  rel="noreferrer"
                >
                  Download our product brochure
                </a>
              </div>
            )}
          </div>
          <div className="html-content col-span-12 md:col-span-5 md:col-start-7">
            <PrismicRichText
              field={primary.content_2.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="md:text-lg fade-up">{children}</p>
                ),
              }}
            />
          </div>
          {primary.image1?.url && (
            <div className="col-span-12 mt-10 md:mt-20">
              <GatsbyImage
                image={primary.image1.gatsbyImageData}
                alt={primary.image1.alt || ''}
                className="block radius-32 [ w-full h-full object-cover ]"
              />
            </div>
          )}
        </div>
      </header>
    );
  }
}
export default BlockHeroOverview;
