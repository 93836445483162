import styled from 'styled-components';
import hero from '../images/home--hero.jpg';
import { media } from '../styles/media';

export const Section = styled.section`
  .bg--hero {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url(${hero});
    background-size: cover;
    border-radius: 1000px 1000px 0 0;
  }
  .form-2 {
    position: relative;
    img {
      width: 100%;
      max-width: initial;
    }
  }
  .form-2-container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .form-2-content {
    padding: 0 30px;
    @media ${media.mdUp} {
      padding: 0 60px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
  .form-1,
  .form-2 {
    display: none;
    &.is-active {
      display: block;
    }
  }
`;
