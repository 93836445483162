import React, { useState, Component } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import logoMark from '../images/the-routing-company-mark.svg';
import logo from '../images/the-routing-company.svg';
import arrow from '../images/arrow--newsletter.svg';

import { media } from '../styles/media';
import Header from './header';

const FooterDiv = styled.footer`
  a {
    text-decoration: none;
  }
  .caption--footer {
    &:hover,
    &:focus {
      .caption--shown {
        display: none;
      }
      .caption--hidden {
        display: block;
        opacity: 1;
      }
      @media ${media.lgUp} {
        .caption--shown {
          display: block;
        }
      }
    }
    .caption--shown {
    }
    .caption--hidden {
      display: none;
      transition: all 0.3s ease-in;
      opacity: 0;
      @media ${media.lgUp} {
        display: block;
        border-left: 1px solid var(--grey-4);
      }
    }
  }
  .newsletter__input {
    background: none;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.01em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 44px;
    border-radius: 36px;
    padding: 3px 5px 0 15px;
    transition: all 0.3s ease-in;
    &:focus {
      border-color: rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  }
  button {
    transition: all 0.3s ease-in;
  }
  button[disabled] {
    opacity: 0.5;
  }
`;

const CookieBar = styled.div`
  position: fixed;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  bottom: 0;
  z-index: 10;
  background: var(--grey-2);
  transform: translateY(100%);
  transition: all 0.3s ease-in;
  p {
    a {
      text-decoration: underline;
      @media ${media.lgUp} {
        text-decoration: none;
      }
    }
  }
  &.is-active {
    transform: translateY(0);
  }
  .cookie--close {
    @media ${media.xlUp} {
      position: absolute;
      right: 20px;
    }
  }
`;

const Footer = (data) => {
  const { global } = data;
  const [mcResponse, setMcResponse] = useState({ email: '', result: null });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (event) => {
    const emailInput = event.target.value;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(emailInput)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  const handleNewsletterSubmit = async (event) => {
    setMcResponse({});
    event.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const formData = new FormData(event.target);

    const emailError = event.target.querySelector('.form__error');
    const emailErrorSubscribed = event.target.querySelector(
      '.form__error-subscribed'
    );
    const emailInput = event.target.querySelector('.newsletter__input');

    if (!emailRegex.test(emailInput.value)) {
      emailError.style.display = 'flex';
      setTimeout(() => {
        emailError.style.display = 'none';
      }, 3000);
    } else {
      const response = await addToMailchimp(formData.get('email'));
      setMcResponse(response);
      updateForms(emailInput, emailError, emailErrorSubscribed, response);
    }
  };

  const updateForms = (
    emailInput,
    emailError,
    emailErrorSubscribed,
    response
  ) => {
    // SUCCESS STATE
    if (response.result === 'success') {
      emailInput.value = "You're on the list!";
      setTimeout(() => {
        emailInput.value = '';
      }, 3000);

      // ERROR STATE
    } else if (response.result === 'error') {
      const responseMessage = String(response.msg);
      const alreadySubscribed = 'already subscribed';
      if (responseMessage.includes(alreadySubscribed)) {
        // IF ON DESKTOP
        if (matches) {
          emailErrorSubscribed.style.display = 'flex';
          setTimeout(() => {
            emailErrorSubscribed.style.display = 'none';
          }, 3000);

          // IF ON MOBILE
        } else {
          emailInput.value = "You're already subscribed!";
          setTimeout(() => {
            emailInput.value = '';
          }, 3000);
        }
      } else if (matches) {
        emailError.style.display = 'flex';
        setTimeout(() => {
          emailError.style.display = 'none';
        }, 3000);
      } else {
        emailInput.value = 'Submission Error';
        setTimeout(() => {
          emailInput.value = '';
        }, 3000);
      }
    }
  };

  return (
    <FooterDiv className="bg--grey-2">
      <div className="container pt-20 md:pt-24 lg:pt-32 pb-10">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 pb-12 md:pb-16 lg:pb-32">
          <div className="col-span-4 md:col-span-2 lg:col-span-3">
            <Link to="/">
              <img
                src={logoMark}
                alt="The Routing Company Logo"
                className="db"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="col-span-2 col-start-1 md:col-start-3 lg:col-start-4 mt-12 md:mt-0">
            <div className="eyebrow mb-4 color--grey-5">Company</div>
            <ul>
              {global.footer_nav_company.map((item, index) => (
                <li key={index}>
                  <Link to={item.url} className="text-sm md:text-lg">
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-2 col-start-3 md:col-start-5 lg:col-start-6 mt-12 md:mt-0">
            <div className="eyebrow mb-4 color--grey-5">Contact</div>
            <ul>
              {global.footer_nav_contact.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    className="text-sm md:text-lg"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-3 col-start-1 md:col-span-3 md:col-start-8 lg:col-start-8 mt-12 md:mt-0">
            <div className="eyebrow mb-4 color--grey-5">Stay Informed</div>
            {mcResponse.result === 'success' ? (
              <p className="text-sm md:text-lg">
                Roger that, you're now subscribed.
              </p>
            ) : (
              <p className="text-sm md:text-lg">
                Subscribe to our{' '}
                <Link to="/newsletter" className="underline">
                  newsletter
                </Link>
              </p>
            )}
            <form
              onSubmit={handleNewsletterSubmit.bind(this)}
              className="w-full mt-3 md:mt-6"
            >
              <div className="newsletter__input-container items-center w-full">
                {mcResponse.result === 'success' ? (
                  <></>
                ) : (
                  <div className="relative">
                    <input
                      onChange={handleChange}
                      type="email"
                      name="email"
                      required
                      className="newsletter__input w-full"
                      placeholder="Your email"
                      autoComplete="chrome-off"
                    />
                    <button
                      disabled={!!submitDisabled}
                      className="newsletter__submit absolute top-1 right-1 hover:opacity-90"
                    >
                      <img src={arrow} alt="" loading="lazy" />
                    </button>
                  </div>
                )}
              </div>

              <div className="form__error hidden font-serif lowercase block text-2xs text-red pt-3">
                <p>Please enter a valid email address</p>
              </div>
              <div className="form__error form__error-subscribed hidden font-serif lowercase block text-2xs text-red pt-3">
                <p>This email is already subscribed!</p>
              </div>
            </form>
          </div>
          <div className="col-span-2 col-start-11 text-right hidden md:block">
            <img
              src={logo}
              alt="The Routing Company"
              className="inline logo"
              loading="lazy"
            />
          </div>
        </div>
        <hr />
        <div className="flex justify-between items-center pt-4">
          <div>
            <p className="caption caption--footer mb-0 flex items-center">
              <span className="caption--shown">
                &copy;{new Date().getFullYear()} The Routing Company. All rights
                reserved.
              </span>
            </p>
          </div>
          <div className="block">
            <ul className="flex justify-end">
              <li className="caption ml-6 md:ml-12">
                <Link to="/terms-of-service">Terms&nbsp;of&nbsp;Service</Link>
              </li>
              <li className="caption ml-6 md:ml-12">
                <Link to="/privacy">Privacy&nbsp;Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FooterDiv>
  );
};

export default Footer;
