import { PrismicRichText } from '@prismicio/react';
import React, { Component } from 'react';
import { Heading } from './BlockHeroOverview';

export const Paragraph = ({ children }) => (
  <p className="text-lg md:text-xl">{children}</p>
);

class BlockContent extends Component {
  render() {
    const {
      slice: { primary },
    } = this.props;

    return (
      <section className="[ BlockContent ] container relative pt-16 md:pt-24">
        <div className="grid grid-cols-12 gap-x-6 md:mb-10">
          <div
            className={`fade-up col-span-12 mb-10 ${
              primary.eyebrow != null
                ? 'md:col-span-7'
                : 'md:col-span-6 md:mb-0'
            }`}
          >
            <Heading
              headingTag={primary.heading_tag ?? 'h1'}
              looksLike={primary?.heading_tag_looks_like}
              className=""
            >
              {primary.heading}
            </Heading>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-6">
          {primary.eyebrow != null && (
            <div className="col-span-12 md:col-span-2 mt-8 md:mt-0">
              <span className="eyebrow-name">{primary.eyebrow}</span>
            </div>
          )}

          <div
            className={`html-content col-span-12 ${
              primary.eyebrow === null ? '' : 'md:col-span-6 md:col-start-6'
            }`}
          >
            {primary.content.richText.length > 0 && (
              <PrismicRichText
                field={primary.content.richText}
                components={{
                  paragraph: Paragraph,
                }}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default BlockContent;
