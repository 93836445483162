import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from 'gsap'

// import styled from 'styled-components'
// import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
// import { Link } from 'gatsby'

export default function BlockTimeline({ slice, data }) {
  const BulletTW =
    'after:absolute after:top-4 after:left-[-3px] after:block after:w-[7px] after:h-[7px] after:bg-black after:rounded-full'
  const LineTW =
    'before:absolute before:top-4 before:left-0 before:-bottom-4 before:border-l-2 before:bg-stone-500 before:w-[1px]'

  function IsLast(arr, idx) {
    return arr.length - 1 === idx
  }
  function toggleActiveNav(targetEl){
    const els = document.querySelectorAll('.timelineCurrent')
    els.forEach((el) => {
      el.classList.remove('timelineCurrent')
    })
    targetEl.classList.add('timelineCurrent')
  }

  const timeline = useStaticQuery(graphql`
    query MyQuery {
      allPrismicTimeline(sort: {order: ASC, fields: data___title}) {
        edges {
          node {
            data {
              title
              body {
                ... on PrismicTimelineDataBodyMonth {
                  items {
                    caption {
                      richText
                    }
                    image {
                      alt
                      url
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  primary {
                    content {
                      richText
                    }
                    month
                  }
                  slice_type
                }
              }
            }
          }
        }
      }
    }
  `)
  // console.log('===timeline.allPrismicTimeline.edges===')
  // console.log(timeline.allPrismicTimeline.edges)

  useEffect(() => {
    // Animate year
    const years = gsap.utils.toArray('.timelineYearSingle')
    years.forEach((el) => {
      const targetEl = document.querySelector(el.getAttribute('data-target'))
      gsap.from(el, {
        scrollTrigger: {
          start: 'top bottom',
          // end: '100% top',
          trigger: el,
          onEnter: function(){
            toggleActiveNav(targetEl)
          },
          onEnterBack: function(){
            toggleActiveNav(targetEl)
          }
          // toggleClass: {
          //   targets: target,
          //   className: 'timelineCurrent'
          // }
        }
      })
    })
    // Animate month to fadein and move upwards
    const months = gsap.utils.toArray('.timelineMonthSingle')
    months.forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          start: 'top bottom',
          // end: 'bottom top',
          trigger: el,
          toggleClass: 'onscreen'
        }
      })
    })
  }, [])

  return (
    <div className="[timelineComp] mt-16 lg:mt-48">
      <section className="[timelineHeaderNav] container">
        <div className="grid grid-cols-12 relative pt-10 md:pt-0">
          <div className="col-span-12 lg:col-span-9 xl:col-span-5">
            <h2 className="h2 mb-10 color--primary fade-up">
            {slice.primary.heading || "Where We’ve Been and Where We’re Going."}
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-12 md:mt-10 mb-10 md:mb-18">
          <div className="col-span-12 md:col-span-2">
            <span className="eyebrow-name fade-up">{slice.primary.eyebrow || "Timeline."}</span>
          </div>
        </div>
      </section>
      <section className="[timelineBody] container relative md:pt-20 pb-10 md:grid grid-cols-12 gap-16">
        <nav className="[ yearsNav ] duration-200 [ sticky top-0 z-[1] md:relative ][ col-span-12 md:col-span-3 lg:col-span-4 ][ pb-8 md:py-0 ] bg-offwhite overflow-x-auto md:overflow-visible max-w-full">
          <ul className="[ md:sticky top-24 ][ text-lg text-stone-500 font-semibold ][ flex gap-4 items-center md:block ]">
            {/** Year/Title navigation */}
            {timeline.allPrismicTimeline.edges.map((year, yearIdx) => (
              <li key={`timeline${year.node.data.title}${yearIdx}`} id={`timelineLink${year.node.data.title}`} className="lg:pb-6">
                <a
                  className="[ yearLink ] leading-none table"
                  href={`#timeline${year.node.data.title}`}
                >
                  {year.node.data.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <section className="[timelineYears] col-span-full md:col-span-9 lg:col-span-8">
          {/* Year loop */}
          {timeline.allPrismicTimeline.edges.map((year, yearIdx) => (
            <section
              key={`yearSection${yearIdx}`}
              id={`timeline${year.node.data.title}`}
              className="[ timelineYearSingle ]"
              data-target={`#timelineLink${year.node.data.title}`}
            >
              <h3 className={`timeLineYearTitle relative pb-6 before:!top-0 ${LineTW}`}>{year.node.data.title}</h3>
              {/* Months loop for the current year: */}
              <ul className="[timelineMonths]">
                {year.node.data.body.map((month, monthIdx) => (
                  <li
                    key={monthIdx}
                    data-target={`#timelineLink${year.node.data.title}`}
                    className={`[ timelineMonthSingle ][ gsapTarget opacity-0 -translate-y-24 duration-500 ] md:grid gap-x-8 grid-cols-2 pl-16 pb-16 relative ${BulletTW} ${IsLast(timeline.allPrismicTimeline.edges, yearIdx) &&
                      IsLast(year.node.data.body, monthIdx)
                      ? ''
                      : LineTW
                      }]`}
                  >
                    <div className="col-span-full md:col-span-1 mb-6">
                      <h4 className="table rounded-2xl font-bold border border-stone-300 px-3 py-2 mb-6">
                        {month.primary.month}<span className="sr-only">, {year.node.data.title}</span>
                      </h4>
                      <PrismicRichText field={month.primary.content.richText} />
                    </div>
                    {/* {month.images && month.images.length && */}
                    <div className="col-span-full md:col-span-1">
                      {month.items.map((img, iimg) => (
                        <figure className="mb-8" key={`img${iimg}`}>
                          {/* <img
                            src={img.image.url}
                            alt=""
                            className="rounded-3xl fade-up max-w-full mb-6"
                          /> */}
                          <GatsbyImage
                            image={img.image.gatsbyImageData}
                            alt={img.image.alt || ''}
                            className="rounded-3xl fade-up max-w-full mb-6"
                          />
                          {img.caption &&
                            <figcaption className="text-stone-500 -mt-2 mb-6">
                              <PrismicRichText field={img.caption.richText} />
                            </figcaption>
                          }
                        </figure>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </section>
      </section>
    </div>
  )
}
