import React, { Component } from "react";

class BlockTest extends Component {
  render() {
    const {
      slice: { primary, items },
    } = this.props;

    console.log("primary: ", primary);
    console.log("items: ", items);

    return (
      <section className="[ BlockTest ] container relative pt-16 md:pt-24">
        HERE!
      </section>
    );
  }
}

export default BlockTest;
